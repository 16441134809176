<template>
    <v-container fluid>
        <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0" style="text-align: -webkit-center;">
                <div style="max-width: 1000px;">
                    <v-carousel hide-delimiters height="auto">
                        <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        ></v-carousel-item>
                    </v-carousel>
                </div>
            </v-col>
            <v-col class="text-center">
                <v-btn
                    class="ma-2"
                    outlined
                    color="indigo"
                    to="/q0"
                    >
                    Saber mi huella hídrica
                </v-btn>
            </v-col>

            <v-col cols="12" class="text-center mt-10">
                <div>
                    The water footprint calculators are under copyright: <br>
                    © Arjen Y. Hoekstra, Ashok K. Chapagain and Mesfin M. Mekonnen
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    src: require('./../assets/b1.jpg'),
                },
                {
                    src: require('./../assets/b2.jpg'),
                },
                {
                    src: require('./../assets/b3.jpg'),
                },
                {
                    src: require('./../assets/b4.jpg'),
                },
                {
                    src: require('./../assets/b5.jpg'),
                },
                {
                    src: require('./../assets/b6.jpg'),
                },
                {
                    src: require('./../assets/b7.jpg'),
                },
                {
                    src: require('./../assets/b8.jpg'),
                },
                {
                    src: require('./../assets/b9.jpg'),
                },
                {
                    src: require('./../assets/b10.jpg'),
                }
            ],
        }
    },
}
</script>